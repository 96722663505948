import { Alert, Box, Button, Flex, Image, Input, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { loginAPI } from "../service/AuthService";
import { Encrypt } from "../utils/constants";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { generateHash } from "../service";
import { useNavigate } from "react-router-dom";
export default function Login() {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      login();
    }
  };

  const login = async () => {
    let fp = await generateHash();
    localStorage.setItem("fingerprint", fp);
    setLoading(true);
    const { response, message, code } = await loginAPI(
      userName,
      Encrypt(password)
    );
    if (userName === "" || password === "") {
      toast.error("Please enter username and password");
      return;
    }
    if (code == 200) {
      localStorage.setItem("token", response.token);
      dispatch({
        type: "accountData/setUserData",
        payload: response,
      });

      if (response?.mfaEnabled) {
        navigate("/otp", {
          state: { userName },
        });
        setLoading(false);
      } else {
        toast.success("Login successful");
        setTimeout(() => {
          window.location.href = "/";
        }, 1200);
        setLoading(false);
      }
    } else {
      toast.error(response);
      setLoading(false);
    }
  };
  return (
    <app-login _nghost-ng-c2875214609="" className="ng-star-inserted">
      <div
        _ngcontent-ng-c2875214609=""
        className="app-body login-agbody login-body"
      >
        <div _ngcontent-ng-c2875214609="" className="login-wrap">
          <div _ngcontent-ng-c2875214609="" className="kv" />
          <dl _ngcontent-ng-c2875214609="" className="login-panel">
            <div _ngcontent-ng-c2875214609="" className="logo-main-div">
              <img
                _ngcontent-ng-c2875214609=""
                className="logo-main ng-star-inserted"
                src="./logo.png"
              />
              {/**/}
              {/**/}
            </div>
            <div
              _ngcontent-ng-c2875214609=""
              noValidate=""
              className="ng-untouched ng-pristine ng-invalid"
            >
              <dd _ngcontent-ng-c2875214609="">
                <div
                  _ngcontent-ng-c2875214609=""
                  className="d-flex align-items-center position-relative"
                >
                  <input
                    _ngcontent-ng-c2875214609=""
                    formcontrolname="userName"
                    id="loginName"
                    placeholder="Username"
                    type="text"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    className="ng-untouched ng-pristine ng-invalid"
                  />
                  <a _ngcontent-ng-c2875214609="" className="eyebtn">
                    <i _ngcontent-ng-c2875214609="" className="fas fa-user" />
                  </a>
                </div>
                {/**/}
              </dd>
              <dd _ngcontent-ng-c2875214609="">
                <div
                  _ngcontent-ng-c2875214609=""
                  className="d-flex align-items-center position-relative"
                >
                  <input
                    _ngcontent-ng-c2875214609=""
                    formcontrolname="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    type="password"
                    className="ng-untouched ng-pristine ng-invalid"
                  />
                  <a _ngcontent-ng-c2875214609="" className="eyebtn">
                    <i _ngcontent-ng-c2875214609="" className="far fa-eye" />
                  </a>
                </div>
                {/**/}
              </dd>
              <dd _ngcontent-ng-c2875214609="">
                <button
                  _ngcontent-ng-c2875214609=""
                  id="loginBtn"
                  type="submit"
                  onClick={login}
                  className="btn-send"
                >
                  Login{" "}
                  <svg
                    _ngcontent-ng-c2875214609=""
                    width={10}
                    height={11}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      _ngcontent-ng-c2875214609=""
                      d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z"
                      fill="currentColor"
                      fillRule="evenodd"
                    />
                  </svg>
                </button>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </app-login>
  );
}
