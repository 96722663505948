import { useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePasswordAPI } from "../service/AuthService";
import { Encrypt } from "../utils/constants";
export default function ChangePasswordNew({ internal }) {
  const ui = useSelector((state) => state.account.ui);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [oldPassword, setOldPassword] = React.useState("");
  const userData = useSelector((state) => state.account.userData);
  const [transactionPassword, setTransactionPassword] = React.useState("");
  const toast = useToast();
    const dispatch = useDispatch();

    const changePassword = async () => {
      if (password === "" || confirmPassword === "" || oldPassword === "") {
        toast({
          title: "Error",
          description: "Please fill all the fields",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
        return;
      }
      if (password !== confirmPassword) {
        toast({
          title: "Error",
          description: "Password and confirm password does not match",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
        return;
      }
      if (password === oldPassword) {
        toast({
          title: "Error",
          description: "New password and old password cannot be same",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
        return;
      }
      if (password.length < 5) {
        toast({
          title: "Error",
          description: "Password must contain at least 5 characters",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
        return;
      }
      try {
        var { data, code, message, response, error } = await changePasswordAPI(
          {
            newPassword: Encrypt(password),
            password: Encrypt(oldPassword),
            resetRequired: false,
            uid: userData.id,
          },
          internal ? userData.token : localStorage.getItem("token")
        );
        if (code === 200) {
          toast({
            title: "Success",
            description: "Password changed successfully",
            status: "success",
            duration: 4000,
            isClosable: true,
          });
          localStorage.clear();
          window.location.href = "/";

        } else {
          toast({
            title: "Error",
            description: "Wrong Password",
            status: "error",
            duration: 4000,
            isClosable: true,
          });
        }
      } catch (e) {
        console.log(e);
        toast({
          title: "Error",
          description: "Something went wrong",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      }
    };
  useEffect(() => {
    toast({
      title: "Info",
      description: "Please change your password",
      status: "info",
      duration: 4000,
      isClosable: true,
    });
  }, []);
  return (
    <app-login _nghost-ng-c2875214609="" className="ng-star-inserted">
      <div
        _ngcontent-ng-c2875214609=""
        className="app-body login-agbody login-body"
      >
        <div _ngcontent-ng-c2875214609="" className="login-wrap">
          <div _ngcontent-ng-c2875214609="" className="kv" />
          <dl _ngcontent-ng-c2875214609="" className="login-panel">
            <div _ngcontent-ng-c2875214609="" className="logo-main-div">
              <img
                _ngcontent-ng-c2875214609=""
                className="logo-main ng-star-inserted"
                src="./logo.png"
              />
              {/**/}
              {/**/}
            </div>
            <div
              _ngcontent-ng-c2875214609=""
              noValidate=""
              className="ng-touched ng-dirty ng-valid ng-submitted"
            >
              <dd _ngcontent-ng-c2875214609="">
                <div
                  _ngcontent-ng-c2875214609=""
                  className="d-flex align-items-center position-relative"
                >
                  <input
                    _ngcontent-ng-c2875214609=""
                    formcontrolname="password"
                    id="password"
                    placeholder="Old Password"
                    type="password"
                    onChange={(e) => setOldPassword(e.target.value)}
                    className="ng-touched ng-dirty ng-valid"
                  />
                  <a _ngcontent-ng-c2875214609="" className="eyebtn">
                    <i _ngcontent-ng-c2875214609="" className="far fa-eye" />
                  </a>
                </div>
                {/**/}
              </dd>
              <dd _ngcontent-ng-c2875214609="">
                <div
                  _ngcontent-ng-c2875214609=""
                  className="d-flex align-items-center position-relative"
                >
                  <input
                    _ngcontent-ng-c2875214609=""
                    formcontrolname="password"
                    id="password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    placeholder="New Password"
                    type="password"
                    className="ng-touched ng-dirty ng-valid"
                  />
                  <a _ngcontent-ng-c2875214609="" className="eyebtn">
                    <i _ngcontent-ng-c2875214609="" className="far fa-eye" />
                  </a>
                </div>
                {/**/}
              </dd>
              <dd _ngcontent-ng-c2875214609="">
                <div
                  _ngcontent-ng-c2875214609=""
                  className="d-flex align-items-center position-relative"
                >
                  <input
                    _ngcontent-ng-c2875214609=""
                    formcontrolname="password"
                    id="password"
                    placeholder="Confirm Password"
                    type="password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    value={confirmPassword}
                    className="ng-touched ng-dirty ng-valid"
                  />
                  <a _ngcontent-ng-c2875214609="" className="eyebtn">
                    <i _ngcontent-ng-c2875214609="" className="far fa-eye" />
                  </a>
                </div>
                {/**/}
              </dd>
              <dd _ngcontent-ng-c2875214609="">
                <button
                  _ngcontent-ng-c2875214609=""
                  id="loginBtn"
                  type="submit"
                  onClick={changePassword}
                  className="btn-send"
                >
                  Change Password{" "}
                  <svg
                    _ngcontent-ng-c2875214609=""
                    width={10}
                    height={11}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      _ngcontent-ng-c2875214609=""
                      d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z"
                      fill="currentColor"
                      fillRule="evenodd"
                    />
                  </svg>
                </button>
              </dd>
              <dd _ngcontent-ng-c2875214609="">
                <button
                  _ngcontent-ng-c2875214609=""
                  id="loginBtn"
                  type="submit"
                  onClick={() => { 
                    dispatch({
                      type: "accountData/logOut",
                      payload: {},
                    });
                    window.localStorage.clear();
                    window.location.href = "/";
                  }}
                  className="btn-send"
                >
                  Log Out{" "}
                  <svg
                    _ngcontent-ng-c2875214609=""
                    width={10}
                    height={11}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      _ngcontent-ng-c2875214609=""
                      d="M5.71 7.706l1.432-1.604H1.778V4.898h5.39L5.71 3.294l.781-.86L9.278 5.5 6.49 8.565l-.78-.86zM1.12 0C.825 0 .564.124.339.372a1.24 1.24 0 0 0-.339.86v8.536c0 .325.113.611.339.86.225.248.486.372.78.372H8.88c.295 0 .556-.124.781-.372a1.24 1.24 0 0 0 .339-.86V7.333H8.88v2.435H1.12V1.232h7.76v2.435H10V1.232a1.24 1.24 0 0 0-.339-.86C9.436.124 9.175 0 8.881 0H1.12z"
                      fill="currentColor"
                      fillRule="evenodd"
                    />
                  </svg>
                </button>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </app-login>
  );
}
