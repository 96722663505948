import { Box, Grid, GridItem, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import CustomInput from "../../components/UI/CustomInput";
import CustomSelect from "../../components/UI/CustomSelect";
import {
  addNewClientAPI,
  ClientWithdrawAndDepositAPI,
  getGlobalSettingsAPI,
  getUserDataAPI,
} from "../../service/UserService";
import { Encrypt, ROLE_MAPPING } from "../../utils/constants";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { checkIfUserNameExistsAPI } from "../../service/AuthService";
import { useLocation } from "react-router-dom";
export default function AddClient({ id, userType }) {
  const [clientData, setClientData] = useState({});
  const [globalSetting, setGlobalSetting] = useState({});
  const [payload, setPayload] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const role = useSelector((state) => state.account.userData.userType);
  const [error, setError] = useState(false);
  const { state } = useLocation();

  const checkIfUserNameExists = async (username) => {
    const { message } = await checkIfUserNameExistsAPI(username);
    return message;
  };
  const setData = (e) => {
    if (e.target.name.endsWith("DownLine")) {
      let currentKey = e.target.name.split("DownLine")[0];
      if (
        e.target.value > clientData.partnershipAndCommission[currentKey] ||
        e.target.value < 0
      ) {
        return;
      }
      setPayload({
        ...payload,
        [e.target.name]: e.target.value,
        [currentKey]:
          clientData.partnershipAndCommission[currentKey] - e.target.value > 0
            ? clientData.partnershipAndCommission[currentKey] - e.target.value
            : 0,
      });
    } else {
      setPayload({ ...payload, [e.target.name]: e.target.value });
    }
    onBlurValidation(e);
  };
  const onBlurValidation = async (e) => {
    let numberCheckRegex = /^[0-9]*$/;
    switch (e.target.name) {
      case "username":
        if (e.target.value.length > 20) {
          // toast.error("Username cannot be greater than 20 characters");
          setError({
            ...error,
            [e.target.name]: "Username cannot be greater than 20 characters",
          });
        } else {
          let errorCopy = { ...error };
          delete errorCopy[e.target.name];
          setError(errorCopy);
        }
        if (e.target.value.includes(" ")) {
          // toast.error("Username cannot contain spaces");
          setError({
            ...error,
            [e.target.name]: "Username cannot contain spaces",
          });
        } else {
          let errorCopy = { ...error };
          delete errorCopy[e.target.name];
          setError(errorCopy);
        }
        if (e.target.value.length < 3) {
          // toast.error("Username must be at least 3 characters long");
          setError({
            ...error,
            [e.target.name]: "Username must be at least 3 characters long",
          });
        } else {
          let errorCopy = { ...error };
          delete errorCopy[e.target.name];
          setError(errorCopy);
        }
        if (e.target.value.length > 3) {
          try {
            let check = await checkIfUserNameExists(e.target.value);

            setError({
              ...error,
              [e.target.name]: {
                message: check,
                isError: check == "Username already exists",
              },
            });
          } catch (err) {
            setError({
              ...error,
              [e.target.name]: {
                message: "Username is already taken",
                isError: true,
              },
            });
          }
        }

        break;

      case "creditReference":
        if (e.target.value.length > 10) {
          //toast.error("Credit reference cannot be greater than 20 characters");
          setError({
            ...error,
            [e.target.name]:
              "Credit reference cannot be greater than 20 characters",
          });
        } else {
          let errorCopy = { ...error };
          delete errorCopy[e.target.name];
          setError(errorCopy);
        }
        if (
          isNaN(e.target.value) ||
          e.target.value < 0 ||
          !numberCheckRegex.test(e.target.value)
        ) {
          //toast.error("Credit reference must be a number");
          setError({
            ...error,
            [e.target.name]: "Credit reference must be a number",
          });
        } else {
          let errorCopy = { ...error };
          delete errorCopy[e.target.name];
          setError(errorCopy);
        }

        break;
      case "exposureLimit":
        if (e.target.value.length > 10) {
          // toast.error("Exposure  cannot be greater than 20 characters");
          setError({
            ...error,
            [e.target.name]: "Exposure  cannot be greater than 20 characters",
          });
        } else {
          let errorCopy = { ...error };
          delete errorCopy[e.target.name];
          setError(errorCopy);
        }
        if (
          isNaN(e.target.value) ||
          e.target.value < 0 ||
          !numberCheckRegex.test(e.target.value)
        ) {
          // toast.error("Exposure must be a number");
          setError({
            ...error,
            [e.target.name]: "Exposure must be a number",
          });
        } else {
          let errorCopy = { ...error };
          delete errorCopy[e.target.name];
          setError(errorCopy);
        }

        break;
      case "city":
        if (e.target.value.length > 20) {
          //  toast.error("City cannot be greater than 20 characters");
          setError({
            ...error,
            [e.target.name]: "City cannot be greater than 20 characters",
          });
        } else if (e.target.value.includes(" ")) {
          //toast.error("City cannot contain spaces");
          setError({
            ...error,
            [e.target.name]: "City cannot contain spaces",
          });
        } else if (parseInt(e.target.value)) {
          //toast.error("City cannot contain numbers");
          setError({
            ...error,
            [e.target.name]: "City cannot contain numbers",
          });
        } else {
          let errorCopy = { ...error };
          delete errorCopy[e.target.name];
          setError(errorCopy);
        }
        break;
      case "password":
        if (e.target.value.length < 8) {
          // toast.error("Password must be at least 3 characters long");
          setError({
            ...error,
            [e.target.name]: "Password must be at least 8 characters long",
          });
        } else if (
          !/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(e.target.value)
        ) {
          setError({
            ...error,
            [e.target.name]:
              "Password must contain at least one uppercase letter, one lowercase letter and one number",
          });
        } else {
          let errorCopy = { ...error };
          delete errorCopy[e.target.name];
          setError(errorCopy);
        }
        break;
      case "confirmPassword":
        if (e.target.value !== payload.password) {
          // toast.error("Password and Confirm Password must be same");
          setError({
            ...error,
            [e.target.name]: "Password and Confirm Password must be same",
          });
        } else {
          let errorCopy = { ...error };
          delete errorCopy[e.target.name];
          setError(errorCopy);
        }
        break;
      case "fullName":
        if (e.target.value.length > 20) {
          // toast.error("Full name cannot be greater than 20 characters");
          setError({
            ...error,
            [e.target.name]: "Full name cannot be greater than 20 characters",
          });
        } else {
          let errorCopy = { ...error };
          delete errorCopy[e.target.name];
          setError(errorCopy);
        }
      default:
        setError(false);
        break;
    }
  };
  const getGlobalSetting = async () => {
    const { response } = await getGlobalSettingsAPI();
    setGlobalSetting(response);
    setPayload({
      ...payload,
      ...response,
    });
  };
  const getUserInfo = async () => {
    const { response } = await getUserDataAPI();
    if (response.userType == "CLIENT") {
      setIsAdmin(false);
    }
    setClientData(response);
  };

  const handleSubmit = async () => {
    let filteredPayload = payload;

    Object.keys(filteredPayload).forEach((key) => {
      if (key.endsWith("DownLine") || key == "") {
        delete filteredPayload[key];
      }
    });
    if (!filteredPayload.creditReference) {
      toast.error("credit referrence is required");
      return;
    }
    if (!filteredPayload.userType) {
      toast.error("User type is required");
      return;
    }
    if (
      !filteredPayload.exposureLimit &&
      filteredPayload.userType == "CLIENT"
    ) {
      toast.error("Exposure limit is required");
      return;
    }

    if (!filteredPayload.fullName) {
      toast.error("Full name is required");
      return;
    }
    //number check for exposure limit and credit limit
    if (
      isNaN(
        filteredPayload.exposureLimit || filteredPayload.exposureLimit < 0
      ) &&
      filteredPayload.userType == "CLIENT"
    ) {
      toast.error("Exposure limit must be a number");
      return;
    }

    if (
      isNaN(filteredPayload.creditReference) ||
      filteredPayload.creditReference < 0
    ) {
      toast.error("Credit reference must be a number");
      return;
    }

    if (filteredPayload.userType == "CLIENT") {
      filteredPayload.minBetCricket = payload.minBetCricket;
      filteredPayload.minBetFootball = payload.minBetFootball;
      filteredPayload.minBetTennis = payload.minBetTennis;
      filteredPayload.maxBetCricket = payload.maxBetCricket;
      filteredPayload.maxBetFootball = payload.maxBetFootball;
      filteredPayload.maxBetTennis = payload.maxBetTennis;
      filteredPayload.delayCricket = payload.delayCricketOdds;
      filteredPayload.delayFootball = payload.delayFootballOdds;
      filteredPayload.delayTennis = payload.delayTennisOdds;
    }
    if (filteredPayload.password !== filteredPayload.confirmPassword) {
      setPayload({
        ...payload,
        password: "",
        confirmPassword: "",
      });
      toast.error("Password and Confirm Password must be same");
      return;
    }

    if (filteredPayload.password.length < 3) {
      setPayload({
        ...payload,
        password: "",
        confirmPassword: "",
      });
      toast.error("Password must be at least 3 characters long");
      return;
    }

    if (payload?.userType != "CLIENT") {
      filteredPayload.commissionInCricket = payload?.commission;
      filteredPayload.commissionInFootball = payload?.commission;
      filteredPayload.commissionInTennis = payload?.commission;
      filteredPayload.commissionInCasino = payload?.commission;
      filteredPayload.partnershipInCricket = payload?.partnership;
      filteredPayload.partnershipInFootball = payload?.partnership;
      filteredPayload.partnershipInTennis = payload?.partnership;
    }

    filteredPayload.parentUserId = id;
    filteredPayload.userType = payload?.userType;

    delete filteredPayload["confirmPassword"];
    filteredPayload.password = Encrypt(payload.password);
    try {
      const { response, code, error, status } = await addNewClientAPI(
        filteredPayload
      );
      if (code === 200) {
        toast.success(response?.status || "User Added Successfully");
        setPayload({});
        setTimeout(() => {
          window.location.href = "/list/" + userType;
        }, 500);
      } else if (code !== 200 && status) {
        toast.error(
          status || "Something went wrong Please check all you field"
        );
        setPayload({
          ...payload,
          password: "",
          confirmPassword: "",
        });
      } else {
        toast.error(
          error.description || "Something went wrong Please check all you field"
        );
        setPayload({
          ...payload,
          password: "",
          confirmPassword: "",
        });
      }
    } catch (err) {
      setPayload({});
      toast.error(
        err.message || "Something went wrong Please check all you field"
      );
    }
  };
  const handleModalSubmit = async (payload) => {
    const { response, code, status } = await ClientWithdrawAndDepositAPI(
      "currentClient.uid",
      {
        balance: payload.amount,
        transactionPassword: payload.transactionPassword,
        remarks: payload.remarks,
        transactionType: "DEPOSIT",
      }
    );
    if (code == 200) {
      toast.success(status);
      setPayload({});
      window.location.reload();
    } else {
      toast.error(response);
    }
  };
  useEffect(() => {
    //check if client name is already taken
    if (payload.username) {
      if (clientData.username == payload.username) {
        toast.error("Username is already taken");
        setPayload({
          ...payload,
          username: "",
        });
      }
    }
  }, [payload]);
  useEffect(() => {
    getGlobalSetting();
    getUserInfo();
  }, []);
  return (
    <div
      _ngcontent-ng-c1207450300=""
      id="AddNewUserModal"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      className="modal fade add-user"
      style={{ display: "none" }}
      aria-hidden="true"
    >
      <div _ngcontent-ng-c1207450300="" className="modal-dialog">
        <div _ngcontent-ng-c1207450300="" className="modal-content">
          <div _ngcontent-ng-c1207450300="" className="modal-header bg-primary">
            <h5
              _ngcontent-ng-c1207450300=""
              id="exampleModalLabel"
              className="modal-title headtext"
            >
              Add User
            </h5>
            <button
              _ngcontent-ng-c1207450300=""
              type="button"
              className="close"
            >
              <span
                _ngcontent-ng-c1207450300=""
                aria-hidden="true"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                ×
              </span>
            </button>
          </div>
          <div _ngcontent-ng-c1207450300="" className="modal-body">
            <app-addnew-downline-user
              _ngcontent-ng-c1207450300=""
              _nghost-ng-c2113226903=""
            >
              <form
                _ngcontent-ng-c2113226903=""
                noValidate=""
                className="ng-dirty ng-touched ng-valid"
              >
                <div _ngcontent-ng-c2113226903="" className="col-md-10">
                  <div _ngcontent-ng-c2113226903="" className="form-group row">
                    <div _ngcontent-ng-c2113226903="" className="col-md-5">
                      Username{" "}
                      <span
                        _ngcontent-ng-c2113226903=""
                        className="asterisk_input"
                      />
                    </div>
                    <div _ngcontent-ng-c2113226903="" className="col-md-7">
                      <input
                        _ngcontent-ng-c2113226903=""
                        autoComplete="off"
                        formcontrolname="userName"
                        name="username"
                        value={payload.username}
                        onChange={setData}
                        placeholder="Username.."
                        required=""
                        type="text"
                        className="form-control ng-dirty ng-valid ng-touched"
                      />
                      {/**/}
                    </div>
                  </div>
                </div>
                <div _ngcontent-ng-c2113226903="" className="col-md-10">
                  <div _ngcontent-ng-c2113226903="" className="form-group row">
                    <div _ngcontent-ng-c2113226903="" className="col-md-5">
                      Name
                    </div>
                    <div _ngcontent-ng-c2113226903="" className="col-md-7">
                      <input
                        _ngcontent-ng-c2113226903=""
                        autoComplete="off"
                        formcontrolname="name"
                        name="fullName"
                        placeholder="Name.."
                        value={payload.fullName}
                        onChange={setData}
                        type="text"
                        className="form-control ng-valid ng-dirty ng-touched"
                      />
                    </div>
                  </div>
                </div>
                {/**/}
                {payload?.userType != "CLIENT" && (
                  <div _ngcontent-ng-c2113226903="" className="col-md-10">
                    <div
                      _ngcontent-ng-c2113226903=""
                      className="form-group row"
                    >
                      <div _ngcontent-ng-c2113226903="" className="col-md-5">
                        Commission(%){" "}
                        <span
                          _ngcontent-ng-c2113226903=""
                          className="asterisk_input"
                        />
                      </div>
                      <div _ngcontent-ng-c2113226903="" className="col-md-7">
                        <input
                          _ngcontent-ng-c2113226903=""
                          formcontrolname="commission"
                          name="commission"
                          placeholder="Commission.."
                          value={payload.commission}
                          onChange={setData}
                          required=""
                          type="text"
                          className="form-control ng-dirty ng-valid ng-touched"
                        />
                        {/**/}
                      </div>
                    </div>
                  </div>
                )}
                {payload?.userType != "CLIENT" && (
                  <div _ngcontent-ng-c2113226903="" className="col-md-10">
                    <div
                      _ngcontent-ng-c2113226903=""
                      className="form-group row"
                    >
                      <div _ngcontent-ng-c2113226903="" className="col-md-5">
                        Partnership(%){" "}
                        <span
                          _ngcontent-ng-c2113226903=""
                          className="asterisk_input"
                        />
                      </div>
                      <div _ngcontent-ng-c2113226903="" className="col-md-7">
                        <input
                          _ngcontent-ng-c2113226903=""
                          formcontrolname="commission"
                          name="partnership"
                          placeholder="Commission.."
                          value={payload.partnership}
                          onChange={setData}
                          required=""
                          type="text"
                          className="form-control ng-dirty ng-valid ng-touched"
                        />
                        {/**/}
                      </div>
                    </div>
                  </div>
                )}
                <div _ngcontent-ng-c2113226903="" className="col-md-10">
                  <div _ngcontent-ng-c2113226903="" className="form-group row">
                    <div _ngcontent-ng-c2113226903="" className="col-md-5">
                      Opening Balance{" "}
                      <span
                        _ngcontent-ng-c2113226903=""
                        className="asterisk_input"
                      />
                    </div>
                    <div _ngcontent-ng-c2113226903="" className="col-md-7">
                      <input
                        _ngcontent-ng-c2113226903=""
                        formcontrolname="bankBalance"
                        name="openingBalance"
                        placeholder="Opening Balance.."
                        required=""
                        value={payload.openingBalance}
                        onChange={setData}
                        type="text"
                        className="form-control ng-dirty ng-valid ng-touched"
                      />
                      {/**/}
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c2113226903=""
                  className="col-md-10 ng-star-inserted"
                >
                  <div _ngcontent-ng-c2113226903="" className="form-group row">
                    <div _ngcontent-ng-c2113226903="" className="col-md-5">
                      User Type:
                      <span
                        _ngcontent-ng-c2113226903=""
                        className="asterisk_input"
                      />
                    </div>
                    <div _ngcontent-ng-c2113226903="" className="col-md-7">
                      <select
                        name="userType"
                        onChange={setData}
                        value={payload.userType}
                        className="form-control ng-dirty ng-valid ng-touched"
                      >
                        <option
                          className="
                        form-control ng-dirty ng-valid ng-touched
                        "
                          value=""
                        >
                          Select User Type
                        </option>
                        {ROLE_MAPPING[role].map((role) => (
                          <option
                            className="form-control ng-dirty ng-valid ng-touched"
                            value={role?.value}
                          >
                            {role?.label}
                          </option>
                        ))}
                      </select>
                      {/**/}
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c2113226903=""
                  className="col-md-10 ng-star-inserted"
                >
                  <div _ngcontent-ng-c2113226903="" className="form-group row">
                    <div _ngcontent-ng-c2113226903="" className="col-md-5">
                      Exposure Limit:
                      <span
                        _ngcontent-ng-c2113226903=""
                        className="asterisk_input"
                      />
                    </div>
                    <div _ngcontent-ng-c2113226903="" className="col-md-7">
                      <input
                        _ngcontent-ng-c2113226903=""
                        placeholder="Exposure Limit"
                        formcontrolname="exposureLimit"
                        name="exposureLimit"
                        value={payload.exposureLimit}
                        onChange={setData}
                        type="number"
                        oninput="validity.valid||(value='');"
                        required=""
                        className="form-control ng-dirty ng-valid ng-touched"
                      />
                      {/**/}
                    </div>
                  </div>
                </div>
                {/**/}
                <div _ngcontent-ng-c2113226903="" className="col-md-10">
                  <div _ngcontent-ng-c2113226903="" className="form-group row">
                    <div _ngcontent-ng-c2113226903="" className="col-md-5">
                      Credit Reference{" "}
                      <span
                        _ngcontent-ng-c2113226903=""
                        className="asterisk_input"
                      />
                    </div>
                    <div _ngcontent-ng-c2113226903="" className="col-md-7">
                      <input
                        _ngcontent-ng-c2113226903=""
                        formcontrolname="creditReference"
                        name="creditReference"
                        value={payload.creditReference}
                        onChange={setData}
                        placeholder="Credit Reference.."
                        required=""
                        type="text"
                        className="form-control ng-dirty ng-valid ng-touched"
                      />
                      {/**/}
                    </div>
                  </div>
                </div>
                <div _ngcontent-ng-c2113226903="" className="col-md-10">
                  <div _ngcontent-ng-c2113226903="" className="form-group row">
                    <div _ngcontent-ng-c2113226903="" className="col-md-5">
                      Mobile Number{" "}
                      <span
                        _ngcontent-ng-c2113226903=""
                        className="asterisk_input"
                      />
                    </div>
                    <div _ngcontent-ng-c2113226903="" className="col-md-7">
                      <input
                        _ngcontent-ng-c2113226903=""
                        formcontrolname="mobileNo"
                        name="contact"
                        value={payload.contact}
                        onChange={setData}
                        placeholder="Mobile Number.."
                        required=""
                        type="text"
                        className="form-control ng-dirty ng-valid ng-touched"
                      />
                      {/**/}
                    </div>
                  </div>
                </div>
                {/**/}
                <div _ngcontent-ng-c2113226903="" className="col-md-10">
                  <div _ngcontent-ng-c2113226903="" className="form-group row">
                    <div _ngcontent-ng-c2113226903="" className="col-md-5">
                      Password{" "}
                      <span
                        _ngcontent-ng-c2113226903=""
                        className="asterisk_input"
                      />
                    </div>
                    <div _ngcontent-ng-c2113226903="" className="col-md-7">
                      <div
                        _ngcontent-ng-c2113226903=""
                        className="d-flex align-items-center position-relative"
                      >
                        <input
                          _ngcontent-ng-c2113226903=""
                          autoComplete="new-password"
                          formcontrolname="password"
                          name="password"
                          value={payload.password}
                          onChange={setData}
                          pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&]{8,}$"
                          placeholder="Password.."
                          required=""
                          className="form-control ng-dirty ng-touched ng-valid"
                          type="text"
                          aria-autocomplete="list"
                        />
                        <a _ngcontent-ng-c2113226903="" className="eyebtn">
                          <i
                            _ngcontent-ng-c2113226903=""
                            className="far fa-eye fa-eye-slash"
                          />
                        </a>
                      </div>
                      {/**/}
                    </div>
                  </div>
                </div>
                <div _ngcontent-ng-c2113226903="" className="col-md-10">
                  <div _ngcontent-ng-c2113226903="" className="form-group row">
                    <div _ngcontent-ng-c2113226903="" className="col-md-5">
                      Confirm Password{" "}
                      <span
                        _ngcontent-ng-c2113226903=""
                        className="asterisk_input"
                      />
                    </div>
                    <div _ngcontent-ng-c2113226903="" className="col-md-7">
                      <div
                        _ngcontent-ng-c2113226903=""
                        className="d-flex align-items-center position-relative"
                      >
                        <input
                          _ngcontent-ng-c2113226903=""
                          formcontrolname="confirmPassword"
                          autoComplete="new-password"
                          name="confirmPassword"
                          value={payload.confirmPassword}
                          onChange={setData}
                          placeholder="Confirm Password.."
                          required=""
                          className="form-control ng-dirty ng-touched ng-valid"
                          type="text"
                        />
                        <a _ngcontent-ng-c2113226903="" className="eyebtn">
                          <i
                            _ngcontent-ng-c2113226903=""
                            className="far fa-eye fa-eye-slash"
                          />
                        </a>
                      </div>
                      {/**/}
                    </div>
                  </div>
                </div>
                <div _ngcontent-ng-c2113226903="" className="col-md-10">
                  <div _ngcontent-ng-c2113226903="" className="form-group row">
                    <div _ngcontent-ng-c2113226903="" className="col-md-5">
                      Rolling Commission
                    </div>
                    <div
                      _ngcontent-ng-c2113226903=""
                      className="col-md-7 text-start"
                    >
                      <label
                        _ngcontent-ng-c2113226903=""
                        htmlFor="RollingCommissionbtn"
                        className="switch switch-label switch-primary pull-left"
                      >
                        <input
                          _ngcontent-ng-c2113226903=""
                          name="isCommission"
                          type="checkbox"
                          className="switch-input"
                        />
                        <span
                          _ngcontent-ng-c2113226903=""
                          data-checked="✓"
                          data-unchecked="✕"
                          className="switch-slider"
                        />
                      </label>
                      <button
                        _ngcontent-ng-c2113226903=""
                        type="button"
                        id="RollingCommissionbtn"
                        className="d-none"
                      />
                    </div>
                  </div>
                </div>
                {/**/}
                {/**/}
                <div _ngcontent-ng-c2113226903="" className="col-md-10">
                  <hr _ngcontent-ng-c2113226903="" />
                  <div _ngcontent-ng-c2113226903="" className="form-group row">
                    <div _ngcontent-ng-c2113226903="" className="col-md-5">
                      Master Password{" "}
                      <span
                        _ngcontent-ng-c2113226903=""
                        className="asterisk_input"
                      />
                    </div>
                    <div _ngcontent-ng-c2113226903="" className="col-md-7">
                      <div
                        _ngcontent-ng-c2113226903=""
                        className="d-flex align-items-center position-relative"
                      >
                        <input
                          _ngcontent-ng-c2113226903=""
                          autoComplete="new-password"
                          formcontrolname="masterPassword"
                          name="transactionPassword"
                          value={payload.transactionPassword}
                          onChange={setData}
                          placeholder="Master Password.."
                          required=""
                          className="form-control ng-touched ng-dirty ng-valid"
                          type="password"
                        />
                        <a _ngcontent-ng-c2113226903="" className="eyebtn">
                          <i
                            _ngcontent-ng-c2113226903=""
                            className="far fa-eye"
                          />
                        </a>
                      </div>
                      {/**/}
                    </div>
                  </div>
                </div>
                <div _ngcontent-ng-c2113226903="" className="text-center mt-3">
                  <button
                    onClick={handleSubmit}
                    _ngcontent-ng-c2113226903=""
                    type="button"
                    className="adduserbtn btn btn-primary"
                  >
                    Create
                  </button>
                </div>
              </form>
            </app-addnew-downline-user>
          </div>
        </div>
      </div>
    </div>
  );
}
