import { Box, Button, Text, useToast, WrapItem } from "@chakra-ui/react";
import React, { useEffect } from "react";
import CustomInput from "../../components/UI/CustomInput";
import CustomTable from "../../components/UI/Table";
import {
  getProfitLossReportAPI,
  searchUserAPI,
} from "../../service/AuthService";
import { getCurrentDateTimeLocal } from "../../utils/constants";
import AsyncSelect from "react-select/async";
import moment from "moment";
export default function ProfitLoss({ userName }) {
  const [searchClientName, setSearchClientName] = React.useState("");
  const [fromDate, setFromDate] = React.useState(
    moment().subtract(7, "d").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = React.useState(moment().format("YYYY-MM-DD"));

  const [profitLossData, setProfitLossData] = React.useState([]);
  const toast = useToast();
  const getProfitLoss = async () => {
    let payload = {
      from: new Date(fromDate),
      to: new Date(toDate),
    };

    const { response, message, code } = await getProfitLossReportAPI(payload);
    if (code == 200) {
      setProfitLossData(response);
    } else {
      toast({
        title: "Error",
        description: response,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  const searchClient = (inputValue) => {
    return searchUserAPI(inputValue).then((response) => {
      return response.response.map((userName) => ({
        value: userName,
        label: userName,
      }));
    });
  };
  useEffect(() => {
    getProfitLoss();
  }, []);

  return (
    <app-profitand-loss _nghost-ng-c81861967="" className="ng-star-inserted">
      <div _ngcontent-ng-c81861967="" className="animated fadeIn divider-top">
        <div _ngcontent-ng-c81861967="" className="row">
          <div _ngcontent-ng-c81861967="" className="col-sm-12 col-md-12">
            <div _ngcontent-ng-c81861967="" className="card card-report">
              <div _ngcontent-ng-c81861967="" className="card-body row p-0">
                <div
                  _ngcontent-ng-c81861967=""
                  className="col-lg-2 col-md-4 col-sm-8"
                >
                  <div _ngcontent-ng-c81861967="" className="form-group">
                    <label _ngcontent-ng-c81861967="" htmlFor="dataSource">
                      Data Source
                    </label>
                    <select
                      _ngcontent-ng-c81861967=""
                      id="dataSource"
                      className="form-control form-select ng-untouched ng-pristine ng-valid"
                    >
                      <option _ngcontent-ng-c81861967="" disabled="" value="">
                        Data Source
                      </option>
                      <option _ngcontent-ng-c81861967="" value="LIVE">
                        LIVE DATA
                      </option>
                      <option _ngcontent-ng-c81861967="" value="BACKUP">
                        BACKUP DATA
                      </option>
                      <option _ngcontent-ng-c81861967="" value="OLD">
                        OLD DATA
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c81861967=""
                  className="col-lg-2 col-md-4 col-sm-12"
                >
                  <div _ngcontent-ng-c81861967="" className="form-group mb-0">
                    <div
                      _ngcontent-ng-c81861967=""
                      htmlFor="startDate"
                      className="mb-2"
                    >
                      From
                    </div>
                    <div _ngcontent-ng-c81861967="" className="input-group">
                      <input
                        _ngcontent-ng-c81861967=""
                        placeholder="Select Date"
                        name="mydate"
                         type="date"
                        readOnly=""
                        autoComplete="off"
                        onChange={(e) => setFromDate(e.target.value)}
                        value={moment(fromDate).format("YYYY-MM-DD")}
                        className="form-control dateClass ng-untouched ng-pristine ng-valid"
                        style={{ padding: "0px !important" }}
                      ></input>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c81861967=""
                  className="col-lg-2 col-md-4 col-sm-12"
                >
                  <div _ngcontent-ng-c81861967="" className="form-group mb-0">
                    <div
                      _ngcontent-ng-c81861967=""
                      htmlFor="endDate"
                      className="mb-2"
                    >
                      To
                    </div>
                    <input
                        _ngcontent-ng-c81861967=""
                        placeholder="Select Date"
                      name="mydate"
                      type="date"
                        readOnly=""
                        autoComplete="off"
                        onChange={(e) => setToDate(e.target.value)}
                        value={moment(toDate).format("YYYY-MM-DD")}
                        className="form-control dateClass ng-untouched ng-pristine ng-valid"
                        style={{ padding: "0px !important" }}
                      ></input>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c81861967=""
                  className="col-lg-2 col-md-4 col-6"
                >
                  <div _ngcontent-ng-c81861967="" className="form-group">
                    <div
                      _ngcontent-ng-c81861967=""
                      htmlFor="type"
                      className="mobile-d-none"
                      style={{ width: "100%" }}
                    >
                      &nbsp;
                    </div>
                    <button
                      onClick={getProfitLoss}
                      _ngcontent-ng-c81861967=""
                      type="button"
                      className="btn btn-primary btsmargin"
                    >
                      <strong _ngcontent-ng-c81861967="">Get P&amp;L</strong>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div _ngcontent-ng-c81861967="" className="row">
          <div _ngcontent-ng-c81861967="" className="col-sm-12 col-md-12">
            <div
              _ngcontent-ng-c81861967=""
              className="card card-accent-primary"
            >
              <div
                _ngcontent-ng-c81861967=""
                className="card-header account-detail-head"
              >
                {" "}
                Profit/Loss{" "}
              </div>
              <div
                _ngcontent-ng-c81861967=""
                className="card-body account-stat-body"
              >
                <div
                  _ngcontent-ng-c81861967=""
                  className="table-responsive table table-striped"
                >
                  <div
                    id="DataTables_Table_28_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div
                      className="dataTables_length"
                      id="DataTables_Table_28_length"
                    >
                      <label>
                        Show{" "}
                        <select
                          name="DataTables_Table_28_length"
                          aria-controls="DataTables_Table_28"
                          className=""
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>{" "}
                        entries
                      </label>
                    </div>
                    <div
                      id="DataTables_Table_28_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        Search:
                        <input
                          type="search"
                          className=""
                          placeholder=""
                          aria-controls="DataTables_Table_28"
                        />
                      </label>
                    </div>
                    <table
                      _ngcontent-ng-c81861967=""
                      datatable=""
                      role="table"
                      aria-busy="false"
                      aria-colcount={6}
                      className="table table-bordered table-striped dataTable no-footer"
                      id="DataTables_Table_28"
                      aria-describedby="DataTables_Table_28_info"
                    >
                      <thead _ngcontent-ng-c81861967="" role="rowgroup">
                        <tr _ngcontent-ng-c81861967="" role="row">
                          <th
                            _ngcontent-ng-c81861967=""
                            className="sorting sorting_desc"
                            tabIndex={0}
                            aria-controls="DataTables_Table_28"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Sport Name: activate to sort column ascending"
                            aria-sort="descending"
                          >
                            Sport Name
                          </th>
                          <th
                            _ngcontent-ng-c81861967=""
                            className="sorting"
                            tabIndex={0}
                            aria-controls="DataTables_Table_28"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Profit &amp; Loss: activate to sort column ascending"
                          >
                            Profit &amp; Loss
                          </th>
                          <th
                            _ngcontent-ng-c81861967=""
                            className="sorting"
                            tabIndex={0}
                            aria-controls="DataTables_Table_28"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Commission: activate to sort column ascending"
                          >
                            Commission
                          </th>
                          <th
                            _ngcontent-ng-c81861967=""
                            className="sorting_disabled"
                            rowSpan={1}
                            colSpan={1}
                            aria-label="Total P&amp;L"
                          >
                            Total P&amp;L
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          profitLossData?.map(
                            (item) => (
                              <tr >
                                <td>{item.eventName}</td>
                                <td>{item.amount}</td>
                                <td>{item.commission}</td>
                                <td>{item.amount}</td>
                              </tr>
                            )
                          )
                        }
                        {profitLossData?.length==0&&<tr className="odd">
                          <td
                            valign="top"
                            colSpan={4}
                            className="dataTables_empty"
                          >
                            No data available in table
                          </td>
                        </tr>}
                      </tbody>
                      {/**/}
                      {
                        profitLossData.length==0&&
                        <tbody>
                        <tr
                          _ngcontent-ng-c81861967=""
                          className="odd ng-star-inserted"
                        >
                          <td
                            _ngcontent-ng-c81861967=""
                            colSpan={10}
                            className="no-data-available"
                          >
                            No data!
                          </td>
                        </tr>
                        {/**/}
                      </tbody>}
                    </table>
                    <div
                      className="dataTables_info"
                      id="DataTables_Table_28_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing 0 to 0 of 0 entries
                    </div>
                    <div
                      className="dataTables_paginate paging_full_numbers"
                      id="DataTables_Table_28_paginate"
                    >
                      <a
                        className="paginate_button first disabled"
                        aria-controls="DataTables_Table_28"
                        aria-disabled="true"
                        role="link"
                        data-dt-idx="first"
                        tabIndex={-1}
                        id="DataTables_Table_28_first"
                      >
                        First
                      </a>
                      <a
                        className="paginate_button previous disabled"
                        aria-controls="DataTables_Table_28"
                        aria-disabled="true"
                        role="link"
                        data-dt-idx="previous"
                        tabIndex={-1}
                        id="DataTables_Table_28_previous"
                      >
                        Previous
                      </a>
                      <span />
                      <a
                        className="paginate_button next disabled"
                        aria-controls="DataTables_Table_28"
                        aria-disabled="true"
                        role="link"
                        data-dt-idx="next"
                        tabIndex={-1}
                        id="DataTables_Table_28_next"
                      >
                        Next
                      </a>
                      <a
                        className="paginate_button last disabled"
                        aria-controls="DataTables_Table_28"
                        aria-disabled="true"
                        role="link"
                        data-dt-idx="last"
                        tabIndex={-1}
                        id="DataTables_Table_28_last"
                      >
                        Last
                      </a>
                    </div>
                  </div>
                  {/**/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-profitand-loss>
  );
}
